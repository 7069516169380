<template>
	<AddEditForm :headerTitle="$t('pages.manager.addSalesman')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="add-salesman" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')">
				<template v-slot:body>
					<CForm>						
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">Sales / Nhà bảo trợ</label>
							<div class="col-sm-9 input-group">								
								<vue-single-select v-model="managerModel"
												   :options="managerList"
												   :required="false"
												   option-label="name"												  
												   :placeholder="$t('pages.manager.selectSalesman')"
												   :max-results="50" style="width:100%"></vue-single-select>
							</div>
						</div>
						
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>	
	import { mapGetters } from 'vuex'  
    import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
    import VueSingleSelect from "vue-single-select";

	export default {
        name: 'salesman-add',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				managerModel: null,
                managerList: []
			}
		},
		computed: {
            //...mapGetters('salesman', ['salesmanInfo']),
		},
		components: {
            Accordion, AddEditForm, VueSingleSelect
		},
		methods: {
            onSave() {
                this.onSalesMember();
            },
            onGoBack() {
                this.$router.push('/salesman/list');
            },
								
            async onSalesMember() {
				
                let profileId = 0

                if (this.managerModel)
                    profileId = this.managerModel.profileId;

				if (profileId <= 0) {
                    this.$store.dispatch('alert/error', this.$t('pages.manager.selectSalesMember'));
                    return;
				}

				var retVal = await this.$salesmanService.addSalesMember(profileId);
               
				if (retVal.data === true) {
					this.onGoBack();
				}
				else {
					if (retVal.errorMessage === 'Existed')
                        this.$store.dispatch('alert/error', this.$t('pages.manager.existedSalesMember'));
                }
			},			           
		},
		async mounted() {
            console.log("getAgentOwnerUserList 1 ==> ");
            //this.$store.dispatch("salesman/initNewSalesman");
			let response = await this.$accountService.getManagerList({ userType: 4 }); // only take user for agent' owner
			this.managerList = response.data;
		}
	}
</script>